<template>
  <div class="main-content">
    <div class="container">
      <div class="blue-text title">課金履歴</div>
      <div class="row mt-4" v-if="billingHistoryTableData">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <div v-if="billingHistoryTableData.data.length > 0">
            <table class="table shadow-1" striped hover>
              <thead>
                <tr>
                  <th scope="col">リクエスト番号</th>
                  <th scope="col">注文数</th>
                  <th scope="col">購入日</th>
                  <th scope="col">ご請求額</th>
                  <th scope="col">領収書</th>
                  <th scope="col">詳細</th>
                </tr>
              </thead>
              <tbody v-if="billingHistoryTableData.data.length === 0">
                <tr v-for="(el, i) in 15" :key="i">
                  <td><b-skeleton></b-skeleton></td>
                  <td><b-skeleton></b-skeleton></td>
                  <td><b-skeleton></b-skeleton></td>
                  <td><b-skeleton></b-skeleton></td>
                  <td><b-skeleton></b-skeleton></td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(el, i) in billingHistoryTableData.data" :key="i">
                  <td
                    data-label="リクエスト番号"
                    class="align-middle text-center"
                  >
                    {{ el.staging_req_id }}
                  </td>
                  <td data-label="注文数" class="align-middle text-center">
                    {{ el.staging_request.request_items.length }}
                  </td>
                  <td data-label="購入日" class="align-middle text-center">
                    {{ formatDate(el.created_at) }}
                  </td>
                  <td data-label="ご請求額" class="align-middle text-center">
                    {{ formatPrice(el.amount) }}
                  </td>
                  <td data-label="領収書" class="align-middle text-center">
                    <router-link
                        :to="{
                        name: 'userRoute-billing-receipt',
                        params: {
                          id: el.staging_req_id,
                        },
                      }"
                    >
                      <button
                          class="btn btn-ds light-blue white-text shadow-1 mt-2 mb-2"
                          v-if="el.is_invoice_payment != '1'"
                      >
                        領収書を発行
                      </button>
                    </router-link>
                  </td>
                  <td data-label="詳細" class="align-middle text-center">
                    <router-link
                      :to="{
                        name: 'userRoute-staging-order-details',
                        params: {
                          id: el.staging_req_id,
                        },
                      }"
                    >
                      <button
                        class="btn btn-ds light-blue white-text shadow-1 mt-2 mb-2"
                      >
                        詳細を表示
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination
              class="mt-3 mb-0"
              :data="pagination.data"
              :limit="pagination.limit"
              :show-disabled="pagination.showDisabled"
              :size="pagination.size"
              :align="pagination.align"
              @pagination-change-page="paginateData"
            />
          </div>
          <div v-else>
            <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
              <img src="/img/icons/ico-info.svg" alt="ico-info" />
              <h3 class="m-0 mt-3 grey-text non-content-text">表示できる内容がありません。</h3>
            </b-card>
          </div>
        </div>
      </div>

      <div class="row mt-5" v-else>
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <table class="table shadow-1" striped hover>
            <thead>
              <tr>
                <th scope="col">リクエスト番号</th>
                <th scope="col">注文数</th>
                <th scope="col">購入日</th>
                <th scope="col">ご請求額</th>
                <th scope="col">詳細</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(el, i) in 15" :key="i">
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
                <td><b-skeleton></b-skeleton></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { cus_stagingService_GetAllBilling } from '../../services/customer/staging';

// Import Moment JS
import moment from 'moment';

// Import laravel Vue Pagination
import LaravelVuePagination from '../../components/Laravel-Vue-Pagination';

import { formatDate, formatPrice } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Billing History',
    };
  },

  components: {
    pagination: LaravelVuePagination,
  },

  data() {
    return {
      billingHistoryTableData: null,
      pagination: {
        data: null,
        limit: 5,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);
      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },

  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },

  methods: {
    formatDate,
    formatPrice,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      cus_stagingService_GetAllBilling({
        token: accessToken,
        page: page ? page : 1,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);
          _this.billingHistoryTableData = data;
          _this.pagination.data = data;
        })
        .catch((error) => {
          console.log(error);
      });
    },
     paginateData(page) {
      let _this = this;
      let currentRouteURLPrams = new URLSearchParams(location.search);
      let url_page = currentRouteURLPrams.get('page');
      if (url_page != page) {
        _this.$router.push({
          name: 'userRoute-billing-history',
          query: { page: page },
        });
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
@media screen and (max-width: 600px) {
  table {
    box-shadow: unset !important;
    border: 0;
    border-radius: 0px !important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    display: block !important;
    margin-bottom: 15px;
  }
  table td {
    display: block;
    text-align: right !important;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    font-weight: bold;
    content: attr(data-label);
    float: left;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .col-xxl-12 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.title {
  font-size: 20px;
}

table tr {
  font-size: 14px;
}

.non-content-text {
  font-size: 19px;
}
</style>
