var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('renderless-laravel-vue-pagination',{attrs:{"data":_vm.data,"limit":_vm.limit,"show-disabled":_vm.showDisabled,"size":_vm.size,"align":_vm.align},on:{"pagination-change-page":_vm.onPaginationChangePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var data = ref.data;
    var limit = ref.limit;
    var showDisabled = ref.showDisabled;
    var size = ref.size;
    var align = ref.align;
    var computed = ref.computed;
    var prevButtonEvents = ref.prevButtonEvents;
    var nextButtonEvents = ref.nextButtonEvents;
    var pageButtonEvents = ref.pageButtonEvents;
return (computed.total > computed.perPage)?_c('ul',{staticClass:"pagination",class:{
      'pagination-sm': size == 'small',
      'pagination-lg': size == 'large',
      'justify-content-center': align == 'center',
      'justify-content-end': align == 'right',
    }},[(computed.prevPageUrl || showDisabled)?_c('li',{staticClass:"page-item pagination-prev-nav",class:{ disabled: !computed.prevPageUrl }},[_c('a',_vm._g({staticClass:"page-link",attrs:{"href":"#","aria-label":"Previous","tabindex":!computed.prevPageUrl && -1}},prevButtonEvents),[_vm._t("prev-nav",function(){return [_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")]),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])]})],2)]):_vm._e(),_vm._l((computed.pageRange),function(page,key){return _c('li',{key:key,staticClass:"page-item pagination-page-nav",class:{ active: page == computed.currentPage }},[_c('a',_vm._g({staticClass:"page-link",attrs:{"href":"#"}},pageButtonEvents(page)),[_vm._v(" "+_vm._s(page)+" "),(page == computed.currentPage)?_c('span',{staticClass:"sr-only"},[_vm._v(" (current) ")]):_vm._e()])])}),(computed.nextPageUrl || showDisabled)?_c('li',{staticClass:"page-item pagination-next-nav",class:{ disabled: !computed.nextPageUrl }},[_c('a',_vm._g({staticClass:"page-link",attrs:{"href":"#","aria-label":"Next","tabindex":!computed.nextPageUrl && -1}},nextButtonEvents),[_vm._t("next-nav",function(){return [_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")]),_c('span',{staticClass:"sr-only"},[_vm._v("Next")])]})],2)]):_vm._e()],2):_vm._e()}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }